import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";
import {
  commonPagination,
  getCurrentClientId,
  getMultiSelectFilterValueForApi,
  getTreeNodeObj,
} from "../../../components/common/Utils/utils";
import { serverRoutes } from "../../../constants/serverRoutes";
import {
  setListingState,
  resetListingState,
} from "../filters/claimManagerFilters";
import { getClaimAllFiltersData_Call } from "../DenialDetails/claimAllFiltersSlice";
import store from "../../store";

let isML = false;
export const drugsRejectedSlice = createSlice({
  name: "drugsRejectedSlice",
  initialState: {
    drugsRejected_data: [],
    rejectedReasonCodes_data: [],
    drugsRejected_GBJcode_data: [],
    drugsRejected_GB_Claim_data: [],
    drugsRejectedDetails_data: {
      billedAmount: null,
      cptCode: null,
      denialAmount: null,
      denialCount: null,
      drugName: null,
      paidAmount: null,
      Claims: [],
    },
    assigneeListData: [],
    assigneeListLoader: false,
  },
  reducers: {
    setDrugsRejectedValues: (state, action) => {
      let data = action.payload;
      state.drugsRejected_data = data;
    },
    setDrugsRejectedReasonCodesValues: (state, action) => {
      let data = action.payload;
      state.rejectedReasonCodes_data = data;
    },

    setDrugsRejectedValuesGBJcode: (state, action) => {
      let data = action.payload;

      state.drugsRejected_GBJcode_data = data;
    },

    setDrugsRejectedGBClaimValues: (state, action) => {
      let data = action.payload;
      state.drugsRejected_GB_Claim_data = data;
    },

    setDrugsRejectedDetail_Action: (state, action) => {
      let data = action.payload;

      if (data?.Claims && data?.Claims.length > 0) {
        state.drugsRejectedDetails_data.Claims = data?.Claims;
        state.drugsRejectedDetails_data.billedAmount = data?.billedAmount;
        state.drugsRejectedDetails_data.cptCode = data?.cptCode;
        state.drugsRejectedDetails_data.denialCount = data?.denialCount;
        state.drugsRejectedDetails_data.drugName = data?.drugName;
        state.drugsRejectedDetails_data.paidAmount = data?.paidAmount;
        state.drugsRejectedDetails_data.denialAmount = data?.denialAmount;
      } else {
        state.drugsRejectedDetails_data.Claims = [];
        state.drugsRejectedDetails_data.billedAmount = null;
        state.drugsRejectedDetails_data.cptCode = null;
        state.drugsRejectedDetails_data.denialCount = null;
        state.drugsRejectedDetails_data.drugName = null;
        state.drugsRejectedDetails_data.paidAmount = null;
        state.drugsRejectedDetails_data.denialAmount = null;
      }
    },

    setAssigneeListData: (state, action) => {
      let data = action.payload;
      const newData = data?.[0]?.map((item) => {
        console.log("item ", item);
        return {
          value: item?.userId,
          label: item?.displayName,
        };
      });
      // console.log(">>>> data ", newData);
      state.assigneeListData = newData;
      state.assigneeListLoader = false;
    },
    setAssigneeListLoader: (state, action) => {
      let data = action.payload;

      state.assigneeListLoader = data;
    },
  },
});

export const {
  setDrugsRejectedValues,
  setDrugsRejectedReasonCodesValues,
  setDrugsRejectedValuesGBJcode,
  setDrugsRejectedDetail_Action,
  setDrugsRejectedGBClaimValues,
  setAssigneeListData,
  setAssigneeListLoader,
} = drugsRejectedSlice.actions;

export const gettingDrugsRejected_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    // handle clear logic for all filters and tabular filter (835 screen)
    const filtersArrNeddToReset = ["drugsRejectedFilter"];
    if (clear === true) {
      filtersArrNeddToReset.map((listing) =>
        dispatch(
          resetListingState({
            listing,
            ignore: ["claimStatus,payers,cptCodes,CARC"],
          })
        )
      );
    }

    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceState = getState().npReducers.filters.drugsRejectedFilter;
    let sliceStateUser = getState().pReducers.user;

    let sliceStateForDateAndFilters =
      getState().npReducers.filters.drugsRejectedFilter;

    let Start = sliceState.pageNumber;
    let Limit = sliceState.pageSize;

    if (Start >= 2) {
      let rec_val = (Start - 1) * Limit;
      Start = rec_val;
    }
    if (Start == 1) {
      Start = 0;
    }

    try {
      ///// API CALL //////

      let paramsTemp = {
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDateAndFilters.from !== "" && {
          From: sliceStateForDateAndFilters.from,
        }),
        ...(sliceStateForDateAndFilters.to !== "" && {
          To: sliceStateForDateAndFilters.to,
        }),
        ...(sliceState.groupByForApi !== "" && {
          Type: sliceState.groupByForApi,
        }),

        ...(sliceStateForDateAndFilters?.cptCodes?.length > 0 && {
          cptCode: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.cptCodes
          ),
        }),
        ...(sliceStateForDateAndFilters?.reasonCodes?.length > 0 && {
          CARC: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.reasonCodes
          ),
        }),
        ...(sliceStateForDateAndFilters?.payers?.length > 0 && {
          PayerId: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.payers
          ),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId?.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };

      let params = {
        Start,
        Limit,
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDateAndFilters.from !== "" && {
          From: sliceStateForDateAndFilters.from,
        }),
        ...(sliceStateForDateAndFilters.to !== "" && {
          To: sliceStateForDateAndFilters.to,
        }),
        ...(sliceState.groupByForApi !== "" && {
          Type: sliceState.groupByForApi,
        }),

        ...(sliceStateForDateAndFilters?.cptCodes?.length > 0 && {
          cptCode: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.cptCodes
          ),
        }),
        ...(sliceStateForDateAndFilters?.reasonCodes?.length > 0 && {
          CARC: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.reasonCodes
          ),
        }),
        ...(sliceStateForDateAndFilters?.payers?.length > 0 && {
          PayerId: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.payers
          ),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId?.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };

      if (sliceStateForDateAndFilters?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObj(
          sliceStateForDateAndFilters.treeNodeServiceLine
        );
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
      }

      const cptParams = {
        CPTCode: "",
        start: 0,
        limit: 100,
        ...(sliceStateForDateAndFilters?.cptCodes?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.cptCodes
          ),
        }),
        ...(sliceStateForDateAndFilters.from !== "" && {
          from: sliceStateForDateAndFilters.from,
        }),
        ...(sliceStateForDateAndFilters.to !== "" && {
          to: sliceStateForDateAndFilters.to,
        }),
      };

      const reasonCodeParams = {
        ...(sliceStateForDateAndFilters.from !== "" && {
          from: sliceStateForDateAndFilters.from,
        }),
        ...(sliceStateForDateAndFilters.to !== "" && {
          to: sliceStateForDateAndFilters.to,
        }),
      };
      const payerParams = {
        ...(sliceStateForDateAndFilters.from !== "" && {
          from: sliceStateForDateAndFilters.from,
        }),
        ...(sliceStateForDateAndFilters.to !== "" && {
          to: sliceStateForDateAndFilters.to,
        }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setDrugsRejectedValues,
        "drugsRejectedFilter",
        "GET_DRUG_REJECTED_GB_CLAIM_DATA_SEP_API",
        null,
        "",
        ""
      );

      dispatch(
        getClaimAllFiltersData_Call(
          cptParams,
          payerParams,
          reasonCodeParams,
          {},
          {},
          {},
          {
            isServiceLineAPI: true,
            isPayersAPI: true,
            isClaimStatusAPI: false,
            isReasonCodeAPI: true,
            isProviderAPI: false,
            isFillingIndicatorAPI: false,
            isBillingProviderAPI: false,
            isRenderingProviderAPI: false,
            isIPOPRemartCodeAPI: false,
            isCptCodeAPI: true,
            isGroupCodeAPI: false,
          }
        )
      );

      ////// API CALL END //////////////

      return 0;
    } catch (e) {}
  };

export const gettingDrugsRejected2_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    // handle clear logic for all filters and tabular filter (835 screen)

    // handle clear logic for all filters and tabular filter (835 screen)
    const filtersArrNeddToReset = [
      "drugsRejectedFilter",
      "drugsRejectedGBClaimFilter",
    ];
    if (clear === true) {
      filtersArrNeddToReset.map((listing) =>
        dispatch(
          resetListingState({
            listing,
            ignore: ["claimStatus,payers,cptCodes,CARC"],
          })
        )
      );
    }

    let sliceState = getState().npReducers.filters.drugsRejectedGBClaimFilter;
    let sliceStateUser = getState().pReducers.user;
    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceStateForDate = getState().npReducers.filters.drugsRejectedFilter;

    let sliceStateForDateAndFilters =
      getState().npReducers.filters.drugsRejectedGBClaimFilter;

    let Start = sliceState.pageNumber;
    let Limit = sliceState.pageSize;

    if (Start >= 2) {
      let rec_val = (Start - 1) * Limit;
      Start = rec_val;
    }
    if (Start == 1) {
      Start = 0;
    }

    try {
      ///// API CALL //////

      let paramsTemp = {
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate.from !== "" && {
          From: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          To: sliceStateForDate.to,
        }),
        ...(sliceState.groupByForApi !== "" && {
          Type: sliceState.groupByForApi,
        }),

        // Include filters only if they have values
        ...(sliceStateForDateAndFilters?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.cptCodes
          ),
        }),
        ...(sliceStateForDateAndFilters?.reasonCodes?.length > 0 && {
          CARC: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.reasonCodes
          ),
        }),
        ...(sliceStateForDateAndFilters?.payers?.length > 0 && {
          PayerId: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.payers
          ),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };

      let params = {
        Start,
        Limit,
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate.from !== "" && {
          From: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          To: sliceStateForDate.to,
        }),
        ...(sliceState.groupByForApi !== "" && {
          Type: sliceState.groupByForApi,
        }),

        // Include filters only if they have values
        ...(sliceStateForDateAndFilters?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.cptCodes
          ),
        }),
        ...(sliceStateForDateAndFilters?.reasonCodes?.length > 0 && {
          CARC: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.reasonCodes
          ),
        }),
        ...(sliceStateForDateAndFilters?.payers?.length > 0 && {
          PayerId: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.payers
          ),
        }),
        ...(sliceStateForDateAndFilters?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.cptCodes
          ),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };

      if (sliceStateForDateAndFilters?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObj(
          sliceStateForDateAndFilters.treeNodeServiceLine
        );
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
      }

      const cptParams = {
        CPTCode: "",
        start: 0,
        limit: 100,
        ...(sliceStateForDateAndFilters?.cptCodes?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.cptCodes
          ),
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      const reasonCodeParams = {
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      const payerParams = {
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      // console.log("Reason Code__API_call--->>", paramsTemp, params);

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setDrugsRejectedReasonCodesValues,
        "drugsRejectedGBClaimFilter",
        "GET_DRUG_REJECTED_GB_REASON_CODE_DATA",
        null,
        "",
        ""
      );

      dispatch(
        getClaimAllFiltersData_Call(
          cptParams,
          payerParams,
          reasonCodeParams,
          {},
          {},
          {},
          {
            isServiceLineAPI: true,
            isPayersAPI: true,
            isClaimStatusAPI: false,
            isReasonCodeAPI: true,
            isProviderAPI: false,
            isFillingIndicatorAPI: false,
            isBillingProviderAPI: false,
            isRenderingProviderAPI: false,
            isIPOPRemartCodeAPI: false,
            isCptCodeAPI: true,
            isGroupCodeAPI: false,
          }
        )
      );

      ////// API CALL END //////////////

      return 0;
    } catch (err) {
      console.log("err--->>", err);
    }
  };

export const gettingDrugsRejectedGroupByJcode_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    // handle clear logic for all filters and tabular filter (835 screen)

    // handle clear logic for all filters and tabular filter (835 screen)
    const filtersArrNeddToReset = [
      "drugsRejectedFilter",
      "drugsRejectedGBJcodeFilter",
    ];
    if (clear === true) {
      filtersArrNeddToReset.map((listing) =>
        dispatch(
          resetListingState({
            listing,
            ignore: ["claimStatus,payers,cptCodes,CARC"],
          })
        )
      );
    }

    let sliceState = getState().npReducers.filters.drugsRejectedGBJcodeFilter;
    let sliceStateUser = getState().pReducers.user;

    let sliceStateForDate = getState().npReducers.filters.drugsRejectedFilter;

    let sliceStateForDateAndFilters =
      getState().npReducers.filters.drugsRejectedGBJcodeFilter;
    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let Start = sliceState.pageNumber;
    let Limit = sliceState.pageSize;

    if (Start >= 2) {
      let rec_val = (Start - 1) * Limit;
      Start = rec_val;
    }
    if (Start == 1) {
      Start = 0;
    }

    try {
      ///// API CALL //////

      let paramsTemp = {
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate.from !== "" && {
          From: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          To: sliceStateForDate.to,
        }),
        ...(sliceState.groupByForApi !== "" && {
          Type:
            sliceState.groupByForApi == "Jcode"
              ? "CPT Code"
              : sliceState.groupByForApi,
        }),

        // Include filters only if they have values
        ...(sliceStateForDateAndFilters?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.cptCodes
          ),
        }),
        ...(sliceStateForDateAndFilters?.reasonCodes?.length > 0 && {
          CARC: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.reasonCodes
          ),
        }),
        ...(sliceStateForDateAndFilters?.payers?.length > 0 && {
          PayerId: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.payers
          ),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };

      let params = {
        Start,
        Limit,
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate.from !== "" && {
          From: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          To: sliceStateForDate.to,
        }),
        ...(sliceState.groupByForApi !== "" && {
          Type:
            sliceState.groupByForApi == "Jcode"
              ? "CPT Code"
              : sliceState.groupByForApi,
        }),

        // Include filters only if they have values
        ...(sliceStateForDateAndFilters?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.cptCodes
          ),
        }),
        ...(sliceStateForDateAndFilters?.reasonCodes?.length > 0 && {
          CARC: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.reasonCodes
          ),
        }),
        ...(sliceStateForDateAndFilters?.payers?.length > 0 && {
          PayerId: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.payers
          ),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };

      if (sliceStateForDateAndFilters?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObj(
          sliceStateForDateAndFilters.treeNodeServiceLine
        );

        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
      }

      const cptParams = {
        CPTCode: "",
        start: 0,
        limit: 100,
        ...(sliceStateForDateAndFilters?.cptCodes?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.cptCodes
          ),
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      const reasonCodeParams = {
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      const payerParams = {
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      // console.log("JCode__API_call--->>", paramsTemp, params);

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setDrugsRejectedValuesGBJcode,
        "drugsRejectedGBJcodeFilter",
        "GET_DRUG_REJECTED_GB_JCODE_DATA",
        null,
        "",
        ""
      );

      dispatch(
        getClaimAllFiltersData_Call(
          cptParams,
          payerParams,
          reasonCodeParams,
          {},
          {},
          {},
          {
            isServiceLineAPI: true,
            isPayersAPI: true,
            isClaimStatusAPI: false,
            isReasonCodeAPI: true,
            isProviderAPI: false,
            isFillingIndicatorAPI: false,
            isBillingProviderAPI: false,
            isRenderingProviderAPI: false,
            isIPOPRemartCodeAPI: false,
            isCptCodeAPI: true,
            isGroupCodeAPI: false,
          }
        )
      );

      ////// API CALL END //////////////

      return 0;
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

// getting DrugsRejected dropdown
export const gettingDrugsRejectedDropdown_Call =
  (target, selCode) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters[target];
    let sliceStateForDate = getState().npReducers.filters.drugsRejectedFilter;
    let sliceStateForDateAndFilters =
      getState().npReducers.filters.drugsRejectedFilter;

    let Start = 0;
    let Limit = 500;

    try {
      ///// API CALL //////

      let params = {
        Start,
        Limit,
        // ...(reasonCode !== "" && {
        //   reasonCode: reasonCode,
        // }),
        ...(selCode !== "" && {
          cptcode: selCode,
        }),
        ...(sliceState.order !== "asc" && {
          order: sliceState.order,
        }),
        ...(sliceState.order !== "asc" && {
          orderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate.from !== "" && {
          From: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          To: sliceStateForDate.to,
        }),
        // ...(sliceStateForDateAndFilters?.cptCodes?.length > 0 && {
        //   cptCodes: getMultiSelectFilterValueForApi(
        //     sliceStateForDateAndFilters?.cptCodes
        //   ),
        // }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_DRUG_REJECTED_DROP_DOWN_LEVEL_ONE_NEW_API,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

// getting DrugsRejected dropdown
export const gettingDrugsRejectedDropdownGroupCode_Call =
  (target, reasonCode) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters[target];
    let sliceStateUser = getState().pReducers.user;

    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceStateForDate = getState().npReducers.filters.drugsRejectedFilter;

    let sliceStateForDateAndFilters =
      getState().npReducers.filters.drugsRejectedFilter;

    let Start = 0;
    let Limit = 500;

    try {
      ///// API CALL //////

      let params = {
        Start,
        Limit,
        ...(reasonCode !== "" && {
          reasonCode: reasonCode,
        }),

        ...(sliceState.order !== "asc" && {
          order: sliceState.order,
        }),
        ...(sliceState.order !== "asc" && {
          orderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate.from !== "" && {
          From: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          To: sliceStateForDate.to,
        }),
        ...(sliceStateForDateAndFilters?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(
            sliceStateForDateAndFilters?.cptCodes
          ),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_DRUG_REJECTED_DROP_DOWN_GROUP_CODE,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

// getting DrugsRejected group by claim first dropdown
export const gettingDrugsRejectedGBClaimFirstDropdown_Call =
  (code, pageStart, pageLimit) => async (dispatch, getState) => {
    // let sliceState = getState().npReducers.filters.drugsRejectedFilter2ndLevel;

    let sliceStateForDate =
      getState().npReducers.filters.reportSreensCommonFilter;

    // let Start = sliceState.pageNumber;
    let Start = pageStart;
    let Limit = pageLimit;

    if (Start >= 2) {
      let rec_val = (Start - 1) * Limit;
      Start = rec_val;
    }
    if (Start == 1) {
      Start = 0;
    }

    try {
      dispatch(
        setListingState({
          listing: "drugsRejectedFilter2ndLevel",
          value: {
            loadingMore: "data-loading",
          },
        })
      );
      ///// API CALL //////

      let params = {
        Start,
        Limit,
        ...{
          cptCode: code,
        },

        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_DRUG_REJECTED_BY_CODE,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      dispatch(
        setListingState({
          listing: "drugsRejectedFilter2ndLevel",
          value: {
            loadingMore: "",
          },
        })
      );

      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);
      dispatch(
        setListingState({
          listing: "drugsRejectedFilter2ndLevel",
          value: {
            loadingMore: "",
          },
        })
      );
      return false;
    }
  };

export const gettingDrugsRejectedGBClaim_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "drugsRejectedFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.drugsRejectedGBClaimFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      dispatch(
        setListingState({
          listing: "drugsRejectedGBClaimFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      let params = {
        start,
        limit,
        ...(sliceState.order !== "asc" && {
          order: sliceState.order,
        }),
        ...(sliceState.order !== "asc" && {
          orderBy: sliceState.orderBy,
        }),
        ...(sliceState.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState.to !== "" && {
          to: sliceState.to,
        }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_DRUG_REJECTED_GB_CLAIM_DATA,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      dispatch(
        setListingState({
          listing: "drugsRejectedGBClaimFilter",
          value: {
            loadingMore: "",
          },
        })
      );

      if (!res?.data?.success) {
        toast.error(`Something went wrong`, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(setDrugsRejectedGBClaimValues([]));
        dispatch(
          setListingState({
            listing: "drugsRejectedGBClaimFilter",
            value: {
              loadingMore: "",
            },
          })
        );
      } else {
        dispatch(setDrugsRejectedGBClaimValues(res?.data?.data));
        dispatch(
          setListingState({
            listing: "drugsRejectedGBClaimFilter",
            value: {
              loadingMore: "",
            },
          })
        );
      }

      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "drugsRejectedGBClaimFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      dispatch(setDrugsRejectedGBClaimValues([]));
      return false;
    }
  };

// drug denial detail api stuff here

export const gettingOnCologyDash_DrugRejecteDetail_Call =
  () => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.drugDenialDetailFilter;
    let sliceStateUser = getState().pReducers.user;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let params = {
        start,
        limit,
        ...(sliceState.selCode !== "" && {
          [sliceState.isMLapi ? "jcodes" : "cptCode"]: sliceState.selCode,
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceState.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState.to !== "" && {
          to: sliceState.to,
        }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceState?.clientId) &&
            sliceState?.clientId.length > 0
              ? sliceState?.clientId[0].value
              : sliceState?.clientId || getCurrentClientId(),
        }),
      };

      let paramsTemp = {
        ...(sliceState.selCode !== "" && {
          [sliceState.isMLapi ? "jcodes" : "cptCode"]: sliceState.selCode,
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceState.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceState?.clientId) &&
            sliceState?.clientId.length > 0
              ? sliceState?.clientId[0].value
              : sliceState?.clientId || getCurrentClientId(),
        }),
      };

      isML = sliceState.isMLapi ? true : false;
      ///// API CALL //////

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setDrugsRejectedDetail_Action,
        "drugDenialDetailFilter",
        sliceState?.isMLapi
          ? "GET_DRUG_REJECTED_BY_CODE_FOR_ML"
          : "GET_DRUG_REJECTED_BY_CODE",
        null,
        "",
        ""
      );

      ////// API CALL END //////////////
    } catch (e) {
      // console.log("e---<<", e);

      return false;
    }
  };

export const getOneDrugDenialData_Call =
  (id, pageStart, pageLimit) => async () => {
    let Start = pageStart;
    let Limit = pageLimit;

    if (Start >= 2) {
      let rec_val = (Start - 1) * Limit;
      Start = rec_val;
    }
    if (Start == 1) {
      Start = 0;
    }

    try {
      ///// API CALL //////

      const res = await genericAxiosCall(
        `${serverRoutes?.GET_PAYMENT_DETAIL_SERVICE_PAYMENT_835}${id}`,
        "get",
        "",
        ""
      );

      ////// API CALL END //////////////

      return res?.data?.data;
    } catch (e) {
      return false;
    }
  };

export const getReasonCodesDetail_Call = (id) => async () => {
  try {
    ///// API CALL //////
    const res = await genericAxiosCall(
      `${serverRoutes?.GET_PAYMENT_DETAIL_SERVICE_ADJUSTMENTS_835}${id}`,
      "get",
      "",
      ""
    );
    ////// API CALL END //////////////

    return res?.data?.data;
  } catch (e) {
    // console.log("e--->>", e);

    return false;
  }
};

export const getClaimAjustmentsForSelectedCpt_Call = (obj) => async () => {
  try {
    let Start = obj.start;
    let Limit = obj.limit;

    if (Start >= 2) {
      let rec_val = (Start - 1) * Limit;
      Start = rec_val;
    }
    if (Start == 1) {
      Start = 0;
    }

    ///// API CALL //////

    const res = await genericAxiosCall(
      `${serverRoutes?.GET_SERVICELINE_DETAIL_ONLY_SELECTED_CPT_API}${obj.id}&cptCode=${obj.cpt}&isPaid=${obj.isPaid}&start=${Start}&limit=${Limit}`,
      "get",
      "",
      ""
    );

    ////// API CALL END //////////////

    return res?.data?.data;
  } catch (e) {
    // console.log("e--->>", e);

    return false;
  }
};

export const getClaimAjustmentsForOncologyDetail_Call =
  (obj, targetFilter) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters[targetFilter];

    let Start = obj.start;
    let Limit = obj.limit;

    if (Start >= 2) {
      let rec_val = (Start - 1) * Limit;
      Start = rec_val;
    }
    if (Start == 1) {
      Start = 0;
    }

    try {
      ///// API CALL //////

      const res = await genericAxiosCall(
        `${serverRoutes?.GET_SERVICELINE_DETAIL_ONLY_SELECTED_CPT_API}${
          obj.id
        }&cptCode=${obj.cpt}&isPaid=${
          obj?.sendisPaid ? obj.isPaid : ""
        }&start=${Start}&limit=${Limit}`,
        "get",
        "",
        ""
      );

      ////// API CALL END //////////////

      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

export const getClaimAdjWorkQueues837Detail_Call =
  (obj, targetFilter) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters[targetFilter];

    let Start = obj.start;
    let Limit = obj.limit;

    if (Start >= 2) {
      let rec_val = (Start - 1) * Limit;
      Start = rec_val;
    }
    if (Start == 1) {
      Start = 0;
    }

    try {
      ///// API CALL //////

      const res = await genericAxiosCall(
        `${serverRoutes?.GET_SERVICELINE_DETAIL_WITH_PAGINATION_API}claimRequestId=${obj?.id}&start=${Start}&limit=${Limit}`,
        "get",
        "",
        ""
      );

      ////// API CALL END //////////////

      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

export const getClaimServices_Call =
  (obj, targetFilter) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters[targetFilter];

    let Start = obj.start;
    let Limit = obj.limit;

    if (Start >= 2) {
      let rec_val = (Start - 1) * Limit;
      Start = rec_val;
    }
    if (Start == 1) {
      Start = 0;
    }

    try {
      ///// API CALL //////

      const res = await genericAxiosCall(
        `${serverRoutes?.DETAIL_OTHER_CLAIM_SERVICE_837}${sliceState?.id}`,
        "get",
        "",
        ""
      );

      ////// API CALL END //////////////

      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

export const getAssigneeDropdownList_Call =
  (obj, targetFilter) => async (dispatch, getState) => {
    try {
      ///// API CALL //////
      dispatch(setAssigneeListLoader(true));

      const res = await genericAxiosCall(
        `${serverRoutes?.GET_All_ASIGNEE_DATA}`,
        "get",
        "",
        ""
      );
      ////// API CALL END //////////////
      dispatch(setAssigneeListData(res?.data?.data?.users));
      return res?.data?.data?.users;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

// change claim status
export const changeAssignee =
  (payload, loadData) => async (dispatch, getState) => {
    try {
      const res = await genericAxiosCall(
        serverRoutes?.UPDATE_ASIGNEE,
        "post",
        payload,
        ""
      );

      if (res?.data?.success == true) {
        // dispatch(setStateChangeLoader(false));

        loadData(true);

        return res?.data?.data;
      } else {
        // dispatch(setStateChangeLoader(false));
        // console.log("123123 res", res?.data);
      }
    } catch (error) {
      // console.log("error--->>", error);
      // dispatch(setStateChangeLoader(false));

      // console.log("123123 err", error);
      toast.error(error?.response?.data?.message);
    }
  };

export default drugsRejectedSlice.reducer;
