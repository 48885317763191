import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { serverRoutes } from "../../../constants/serverRoutes";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";

import {
  setListingState,
  resetListingState,
} from "../filters/claimManagerFilters";
import { commonPagination } from "../../../components/common/Utils/utils";
import { getMultiSelectFilterValueForApi } from "./../../../components/common/Utils/utils";

export const ClientsConfigSlice = createSlice({
  name: "ClientsConfigSlice",
  initialState: {
    clientsConfigTabularList: [],
    clientsListDropdown: [],
    selectedClientIds: "0",
    clientsListLoader: false,
  },
  reducers: {
    setClientsConfigTabular_data: (state, action) => {
      let data = action.payload;

      if (data?.length) {
        state.clientsConfigTabularList = data;
      } else {
        state.clientsConfigTabularList = [];
      }
    },
    setClientsListDropdownData: (state, action) => {
      let data = action.payload;

      if (data?.length) {
        state.clientsListDropdown = data.map((item) => {
          return { label: item.clientName, value: item.clientId };
        });
      } else {
        state.clientsListDropdown = [];
      }
      state.clientsListLoader = false;
    },
    setSelectedClientIds: (state, action) => {
      let data = action.payload;

      state.selectedClientIds = data;
    },
    setClientsListLoader: (state, action) => {
      let data = action.payload;
      state.clientsListLoader = data;
    },
  },
});

export const {
  setClientsConfigTabular_data,
  setClientsListDropdownData,
  setSelectedClientIds,
  setClientsListLoader,
} = ClientsConfigSlice.actions;

// get activity logs list
export const gettingClientsConfigList_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      if (clear === true) {
        dispatch(
          resetListingState({
            listing: "clientsConfigFilter",
            ignore: ["claimStatus,payers,cptCodes,CARC"],
          })
        );
      }
      if (clear === "resetBtn") {
        dispatch(
          resetListingStateForResetButton({
            listing: "clientsConfigFilter",
            ignore: [
              "BillingProviderIds",
              "frequencyType",
              "frequencyType",
              "search",
            ],
          })
        );
      }
    }
    let sliceState = getState().npReducers.filters.clientsConfigFilter;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceState?.fetchLatest === true && { timestamp: Date.now() }),
      };
      let params = {
        start,
        limit,
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceState?.fetchLatest === true && { timestamp: Date.now() }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setClientsConfigTabular_data,
        "clientsConfigFilter",
        "GET_CLIENT_CONFIG_ALL_LIST",
        false,
        "",
        ""
      );

      ///// API CALL //////
    } catch (e) {
      console.log("e--->>", e);
    }
  };

// delete single client
export const deleteSingleClientConfig_Call =
  (id, setLoadingDeleteClass, setOpenDeleteModal, setUpdateObj, list) =>
  async (dispatch, getState) => {
    try {
      const res = await genericAxiosCall(
        `${serverRoutes.DELLETE_CLIENT_CONFIG_ITEM}/${id}`,
        "delete",
        "",
        ""
      );
      if (res?.data?.success === true) {
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });

        setOpenDeleteModal(false);
        dispatch(
          setListingState({
            listing: "clientsConfigFilter",
            value: {
              fetchLatest: true,
            },
          })
        );
        setUpdateObj({});
        if (list.length === 1) {
          console.log();
          dispatch(
            setListingState({
              listing: "clientsConfigFilter",
              value: {
                pageNumber: 1,
              },
            })
          );
        }
        dispatch(gettingClientsConfigList_Call());

        return res;
      }
    } catch (error) {
      // console.log("e0--->>", error);
    } finally {
      // console.log("e0--->>", error);
      setLoadingDeleteClass("");
    }
  };

// delete single client
// export const addUpdateClientConfig_Call =
//   (
//     obj,
//     method,
//     setSubmitLoading,
//     setLoadingAddUpdateClass,
//     setNewOpen,
//     setFormValues,
//     updateObj
//   ) =>
//   async (dispatch, getState) => {
//     try {
//       // UPDATE_CLIENT
//       await genericAxiosCall(
//         `${
//           Object.keys(updateObj).length > 0
//             ? serverRoutes.UPDATE_CLIENT
//             : serverRoutes.ADD_NEW_CLIENT
//         }`,
//         method,
//         obj,
//         ""
//       )
//         .then(async (res) => {
//           if (res?.data?.success === true) {
//             toast.success(res?.data?.message, {
//               position: toast.POSITION.TOP_CENTER,
//             });
//             setNewOpen(false);
//             setFormValues({
//               ClientName: "",
//               IsDefault: false,
//               // CreatedBy: "",
//               // CreatedOn: "",
//               // UpdatedBy: "",
//               // UpdatedOn: "",
//               // Deactive: "",
//               // DeactiveOn: "",
//             });
//             dispatch(
//               setListingState({
//                 listing: "clientsConfigFilter",
//                 value: {
//                   fetchLatest: true,
//                 },
//               })
//             );

//             dispatch(gettingClientsConfigList_Call());

//             return res;
//           } else if (res?.data?.success === false) {
//             toast.error(res?.data?.message, {
//               position: toast.POSITION.TOP_CENTER,
//             });
//           }
//         })
//         .catch((error) => {
//           // console.log("e0--->>", error);
//         });
//     } catch (error) {
//       // console.log("e1--->>", error);
//     } finally {
//       setSubmitLoading(false);
//       setLoadingAddUpdateClass("");
//     }
//   };

export const addUpdateClientConfig_Call =
  (
    obj,
    method,
    setSubmitLoading,
    setLoadingAddUpdateClass,
    setNewOpen,
    setFormValues,
    setUpdateObj
  ) =>
  async (dispatch, getState) => {
    try {
      const route =
        method == "put"
          ? serverRoutes.UPDATE_CLIENT
          : serverRoutes.ADD_NEW_CLIENT;

      const res = await genericAxiosCall(route, method, obj, "");

      if (res?.data?.success) {
        const { message } = res?.data;
        toast.success(message, { position: toast.POSITION.TOP_CENTER });
        setNewOpen(false);
        setFormValues({ ClientName: "", IsDefault: false });

        dispatch(
          setListingState({
            listing: "clientsConfigFilter",
            value: { fetchLatest: true },
          })
        );

        dispatch(gettingClientsConfigList_Call());
        setUpdateObj({});
      } else if (res?.data?.success === false) {
        const { message } = res?.data;
        toast.error(message, { position: toast.POSITION.TOP_CENTER });
      }
    } catch (error) {
      console.log(" --->>", error);
    } finally {
      setSubmitLoading(false);
      setLoadingAddUpdateClass("");
    }
  };

export const gettingClientsConfigListForDropdown =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.clientsConfigFilter;
    dispatch(setClientsListLoader(true));
    let start = 0;
    let limit = 50;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      let params = {
        start,
        limit,
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceState?.fetchLatest === true && { timestamp: Date.now() }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_CLIENT_CONFIG_ALL_LIST,
        "get",
        "",
        params
      );
      if (res?.data?.success == true) {
        let data = res?.data?.data;
        dispatch(setClientsListDropdownData(data));
        dispatch(setSelectedClientIds(data?.[0]?.value));
      }

      dispatch(setClientsListLoader(false));
    } catch (e) {
      console.log("e--->>", e);
      dispatch(setClientsListLoader(false));
    }
  };

export default ClientsConfigSlice.reducer;
