import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  Drawer,
  List,
  Collapse,
  CssBaseline,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";

import ArrowUpMenu from "../../../assets/svgs/ArrowUpMenu";
import ArrowDownMenu from "../../../assets/svgs/ArrowDownMenu";

import browserRoute from "../../../constants/browserRoutes";
import V2ClaimManagerIcon from "../../../assets/svgs/V2ClaimManagerIcon";
import V2OncologyIcon from "../../../assets/svgs/V2OnlogogyIcon";
import V2ReportsIcon from "../../../assets/svgs/V2ReportsIcon";
import V2RemittanceIcon from "../../../assets/svgs/V2RemitanceIcon";
import V2SettingIcon from "../../../assets/svgs/V2SettingIcon";
import V2LeftNavIcon from "../../../assets/svgs/V2LeftNavIcon";
import V2HospitalIcon from "../../../assets/svgs/V2HospitalIcon";

import HeatmapIcon from "../../../assets/svgs/HeatmapIcon";

import { setListingState } from "../../../Redux/Reducers/filters/claimManagerFilters";
import { useDispatch } from "react-redux";
import V2NotificationIcon from "../../../assets/svgs/V2NotificationIcon";
import QueueIcon from "../../../assets/svgs/QueueIcon";

import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import DRAppealIcon from "../../../assets/svgs/DRAppealIcon";
// import BootstrapTooltip from "../TooltipUI/customToolTip";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import DRAvaChatIcon from "../../../assets/svgs/DRAvaChatIcon";
import {
  getterAllBoughtSubscriptionModules,
  getterRoleBasedModules,
  moduleRolesEnum,
  subscriptionPlanNameKey,
} from "../Utils/utils";
import AppLunchar from "./ModualSideDrop";
import { setCurrentModuleRedux } from "../../../Redux/Reducers/UserReducer";
import BootstrapTooltip from "../TooltipUI/customToolTip";
import { environment } from "../../../constants/serverRoutes";
import DRKnowledgeBaseIcon from "../../../assets/svgs/DRKnowledgeBaseIcon";
import DRElgibilityBotIcon from "../../../assets/svgs/DRElgibilityBotIcon";
import ChatIconBot from "../../../pages/AskZoe/ChatIconBot";
import { getCopilotHistoryOutputList } from "../../../Redux/Reducers/UploadPolicy/UploadPolicySlice";
import ChatHistory from "../../../pages/AskZoe/ChatHistory";
import DeleteIcon from "../../../assets/svgs/DeleteIcon";
import { Popover } from "antd";
import DRMoreDropIcon from "../../../assets/svgs/DRMoreDropIcon";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    placement="right"
    // open={true}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#131722",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight: "400",
    marginLeft: "5px",
  },
}));

const SideBarBot = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const pathname = location.pathname;
  const { openDrawer } = useSelector(
    (state) => state.npReducers.filters.userPreferencesFilter
  );
  const { currentModule } = useSelector((state) => state.pReducers.user);
  const { appealsDetailsPageFlag } = useSelector(
    (state) => state.npReducers.claimQueue
  );
  const { policyCopilotHistoryOutputList: copilotHistoryOutputList } =
    useSelector((state) => state.npReducers.uploadPolicy);
  const { loadingMore: chatHistoryLoading } = useSelector(
    (state) => state.npReducers.filters.PolicyCopilotFilter
  );
  const handleSelectModule = (modules) => {
    // console.log("handleSelectModule_1--->>", moduleRoles);

    dispatch(setCurrentModuleRedux(modules));
    setCurrentModuleState(modules);
  };

  // useEffect(() => {
  //   if (location?.pathname === browserRoute?.ASK_ZOE) {
  //     dispatch(
  //       setListingState({
  //         listing: "userPreferencesFilter",
  //         value: {
  //           openDrawer: false,
  //           openDrawerChatbot: false,
  //         },
  //       })
  //     );
  //   }
  // }, [location]);
  const handleSideNavState = () => {
    dispatch(
      setListingState({
        listing: "userPreferencesFilter",
        value: {
          chatbotEpandWidth: false,
        },
      })
    );
    dispatch(
      setListingState({
        listing: "userPreferencesFilter",
        value: {
          openDrawer: !openDrawer,
          openDrawerChatbot: !openDrawer && false,
        },
      })
    );
  };
  const url = pathname.split("/")[1];
  // ===============================================================

  // ===================================  new code start here ===================================

  const { userToken, loginUserData } = useSelector(
    (state) => state.pReducers.user
  );
  const { modules: moduleRoles, roles, OrganizationName } = loginUserData;

  // for handle Role base access

  const [administrator, setAdministrator] = useState(false);
  const [analyst, setAnalyst] = useState(false);
  const [manager, setManager] = useState(false);
  const [executive, setExecutive] = useState(false);
  const [appealRole, setAppealRole] = useState(false);
  const [denialsRole, setDenialsRole] = useState(false);
  const [policyRole, setPolicyRole] = useState(false);

  // for Dropdown collapse

  // below code for Super Admin app side bar collapse
  const [management, setManagement] = useState(false);
  const [support, setSupport] = useState(false);
  const [security, setSecurity] = useState(false);
  const [masterData, setMasterData] = useState(false);

  // below code for user app side bar collapse
  const [insights, setInsights] = useState(false);
  const [denials, setDenials] = useState(false);
  const [claimManager, setClaimManger] = useState(false);
  const [reports, setReports] = useState(false);
  const [remittenceAdvice, setRemittenceAdvice] = useState(false);
  const [onCology, setOnCology] = useState(false);
  const [appeals, setAppeals] = useState(false);
  const [copilot, setCopilot] = useState(false);
  const [sih, setSih] = useState(false);
  const [currentModuleState, setCurrentModuleState] = useState(currentModule);

  const handleDropdown = (dropdownType) => {
    try {
      // handle dropdown for user app

      if (dropdownType == "sih") {
        // console.log("dropdownType__1--->>", dropdownType);

        if (sih) {
          setSih(false);
        } else {
          setSih(!sih);
          setDenials(false);
          setClaimManger(false);
          setReports(false);
          setRemittenceAdvice(false);
          setOnCology(false);
        }
      }
      if (dropdownType == "Insights") {
        // console.log("dropdownType__2--->>", dropdownType);

        if (insights) {
          setInsights(false);
        } else {
          setInsights(!insights);
          setDenials(false);
          setClaimManger(false);
          setReports(false);
          setRemittenceAdvice(false);
          setOnCology(false);
        }
      }
      if (dropdownType == "Reports") {
        // console.log("dropdownType__3--->>", dropdownType);

        if (reports) {
          setReports(false);
        } else {
          setInsights(false);
          setDenials(false);
          setClaimManger(false);
          setReports(!reports);
          setRemittenceAdvice(false);
          setOnCology(false);
        }
      }
      if (dropdownType == "Denials") {
        // console.log("dropdownType__4--->>", dropdownType);

        if (denials) {
          setDenials(false);
        } else {
          setInsights(false);
          setDenials(!denials);
          setClaimManger(false);
          setReports(false);
          setRemittenceAdvice(false);
          setOnCology(false);
        }
      }
      if (dropdownType == "claimManager") {
        // console.log("dropdownType__5--->>", dropdownType);

        if (claimManager) {
          setClaimManger(false);
        } else {
          setInsights(false);
          setDenials(false);
          setClaimManger(!claimManager);
          setReports(false);
          setRemittenceAdvice(false);
          setOnCology(false);
        }
      }

      if (dropdownType == "remittenceAdvice") {
        // console.log("dropdownType__6--->>", dropdownType);

        if (remittenceAdvice) {
          setRemittenceAdvice(false);
        } else {
          setInsights(false);
          setDenials(false);
          setClaimManger(false);
          setReports(false);
          setRemittenceAdvice(!remittenceAdvice);
          setOnCology(false);
        }
      }

      if (dropdownType == "onCology") {
        // console.log("dropdownType__7--->>", dropdownType);

        if (onCology) {
          setOnCology(false);
        } else {
          setInsights(false);
          setDenials(false);
          setClaimManger(false);
          setReports(false);
          setRemittenceAdvice(false);
          setOnCology(!onCology);
        }
      }
      if (dropdownType == "appeals") {
        // console.log("dropdownType__8--->>", dropdownType);

        if (appeals) {
          setAppeals(false);
        } else {
          setSih(false);
          setDenials(false);
          setClaimManger(false);
          setReports(false);
          setRemittenceAdvice(false);
          setOnCology(false);
          setAppeals(!appeals);
        }
      }

      if (dropdownType == "copilot") {
        // console.log("dropdownType__9--->>", dropdownType);

        if (copilot) {
          setCopilot(false);
        } else {
          setSih(false);
          setDenials(false);
          setClaimManger(false);
          setReports(false);
          setRemittenceAdvice(false);
          setOnCology(false);
          setAppeals(false);
          setCopilot(!copilot);
        }
      }

      // handle dropdown for super admin

      if (dropdownType == "management") {
        setManagement(!management);
        setSupport(false);
        setSecurity(false);
        setMasterData(false);
      }

      if (dropdownType == "support") {
        setManagement(false);
        setSupport(!support);
        setSecurity(false);
        setMasterData(false);
      }

      if (dropdownType == "security") {
        setManagement(false);
        setSupport(false);
        setSecurity(!security);
        setMasterData(false);
      }

      if (dropdownType == "masterData") {
        setManagement(false);
        setSupport(false);
        setSecurity(false);
        setMasterData(!masterData);
      }
    } catch (err) {
      console.log("err--->>", err);
    }
  };

  //======= handle Overall System Heat map logic start =============
  const [selDemparment, setSelDepartment] = useState("");

  //======= handle Overall System Heat map logic end =============
  function DenialsModule(props) {
    const { children } = props;
    const userRoles = getterRoleBasedModules();
    return (
      <>
        {denialsRole &&
        userRoles?.find((role) =>
          [moduleRolesEnum?.Denials]?.includes(role?.[subscriptionPlanNameKey])
        )
          ? children
          : null}
      </>
    );
  }
  function AppealsModule(props) {
    const { children } = props;
    const userRoles = getterRoleBasedModules();

    return (
      <>
        {appealRole &&
        userRoles?.find((role) =>
          [moduleRolesEnum?.Appeals]?.includes(role?.[subscriptionPlanNameKey])
        )
          ? children
          : null}
      </>
    );
  }
  function PolicyModule(props) {
    const { children } = props;
    const userRoles = getterRoleBasedModules();

    return (
      <>
        {policyRole &&
        userRoles?.find((role) =>
          [moduleRolesEnum?.Policy]?.includes(role?.[subscriptionPlanNameKey])
        )
          ? children
          : null}
      </>
    );
  }
  function DenialsWrapper(props) {
    const { children } = props;

    return (
      <>
        {currentModuleState?.value?.toLowerCase()?.includes("denials")
          ? children
          : null}
      </>
    );
  }
  function AppealsWrapper(props) {
    const { children } = props;

    return (
      <>
        {currentModuleState?.value?.toLowerCase()?.includes("appeals") ||
        currentModuleState?.value?.toLowerCase()?.includes("denials")
          ? children
          : null}
      </>
    );
  }
  function OnlyAppealsWrapper(props) {
    const { children } = props;

    return (
      <>
        {currentModuleState?.value?.toLowerCase()?.includes("appeals")
          ? children
          : null}
      </>
    );
  }
  function OnlyPolicyWrapper(props) {
    const { children } = props;

    return (
      <>
        {currentModuleState?.value
          ?.toLowerCase()
          ?.includes(moduleRolesEnum?.Policy?.toLocaleLowerCase())
          ? children
          : null}
      </>
    );
  }
  function PolicyWrapper(props) {
    const { children } = props;

    return (
      <>
        {/* {currentModuleState?.toLowerCase()?.includes("policy") ||
        currentModuleState?.toLowerCase()?.includes("denials")
          ? children
          : null} */}
        {children}
      </>
    );
  }
  useEffect(() => {
    let hasUserRole = [];
    let hasModuleRoles = [];
    if (roles?.length) {
      hasUserRole = roles.map((a) => a.name);
    }
    if (moduleRoles?.length) {
      hasModuleRoles = moduleRoles?.map((a) => a.moduleName);
    }
    setAppealRole(hasModuleRoles?.includes(moduleRolesEnum?.Appeals));
    setDenialsRole(hasModuleRoles?.includes(moduleRolesEnum?.Denials));
    setPolicyRole(hasModuleRoles?.includes(moduleRolesEnum?.Policy));
    if (hasUserRole?.length) {
      // console.log("hasUserRole--->>", hasUserRole);

      // Different Roles in Organization Structure for Users
      setAdministrator(hasUserRole.includes("Administrator"));
      setAnalyst(hasUserRole.includes("Analyst"));
      setManager(hasUserRole.includes("Manager"));
      setExecutive(hasUserRole.includes("Executive"));
    }
  }, [userToken, moduleRoles]);
  useEffect(() => {
    setCurrentModuleState(currentModule);
    dispatch(getCopilotHistoryOutputList());
  }, [currentModule]);

  useEffect(() => {
    // execute on location change
    // toast.dismiss();
    // console.log("123123 >>> ", pathname);
    if (
      pathname === browserRoute?.DOMAIN_COPILOT ||
      pathname === browserRoute?.UPLOAD_DOCOMENTS ||
      pathname === "/ask-zoe" ||
      pathname === "/eligibility" ||
      (pathname === "/ask-zoe") === browserRoute?.ASK_ROVERS
    ) {
      const modules = {
        icon: { type: "img", key: null, ref: null },
        label: "Copilot",
        navLocation: "/ask-zoe",
        value: "Policy",
        wrapper: "Policy",
      };

      // console.log("handleSelectModule_2--->>", modules);

      // dispatch(setCurrentModuleRedux(modules));
      // setCurrentModuleState(modules);
    } else if (
      pathname === browserRoute?.APPEALS_DASHBOARD ||
      url === `${browserRoute?.APPEALS_QUEUE_CLAIM.split("/")[1]}` ||
      url === `${browserRoute?.APPEAL_QUEUE_DETAIL.split("/")[1]}` ||
      url === `${browserRoute?.ATTACHMENTS_PREVIEW.split("/")[1]}` ||
      url === `${browserRoute?.Queue_COMMENTS.split("/")[1]}` ||
      pathname === "/ra" ||
      pathname === "/appeal-queue" ||
      url === "appeal-claim-details" ||
      (url === "queue-comments" && appealsDetailsPageFlag) ||
      url === "attachments" ||
      url === "ra-sl" ||
      // url === "partial" ||
      // url === "rev" ||
      url === "ra-up" ||
      url === "ra-den" ||
      url === "ra-det"
    ) {
      const modules = {
        icon: { type: "img", key: null, ref: null },
        label: "Smart Appeals",
        navLocation: "/appeals-dashboard",
        value: "Appeals",
        wrapper: "Appeals",
      };

      // console.log("handleSelectModule_3--->>", modules);

      // dispatch(setCurrentModuleRedux(modules));
      // setCurrentModuleState(modules);
    } else {
      const modules = {
        icon: { type: "img", key: null, ref: null },
        label: "Denials 360",
        navLocation: "/",
        value: "Denials",
        wrapper: "Denials",
      };

      // console.log("handleSelectModule_4--->>", modules);

      // dispatch(setCurrentModuleRedux(modules));
      // setCurrentModuleState(modules);
    }
  }, [location]);

  return (
    <div
    // className={classes.root}
    >
      <CssBaseline />

      <Drawer
        variant="permanent"
        className={{
          ["DR-new-open"]: openDrawer,
          ["DR-new-close DR-llm-side"]: !openDrawer,
        }}
      >
        <div id="Step#2" style={{ height: "100%" }}>
          <div
            className="DR-v2-New-logo-left"
            style={{ backgroundColor: "#fff" }}
          >
            {openDrawer && (
              <p className="DR-logo-name">{currentModuleState?.label} </p>
            )}

            {/* <div
              className="drawer-toolbar"
              style={{
                top: "50px",
                right: "-30px",
                width: "24px",
                height: "24px",
              }}
            >
              {openDrawer ? (
                <BootstrapTooltip title="Collapse Left Bar">
                  <span>
                    <IconButton
                      style={{
                        padding: "0px",
                        width: "24px",
                        height: "24px",
                        backgroundColor: "#fff",
                      }}
                      onClick={handleSideNavState}
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-align-justified"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1"
                        stroke="#131722"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M4 6l16 0" />
                        <path d="M4 12l16 0" />
                        <path d="M4 18l12 0" />
                      </svg>
                    </IconButton>
                  </span>
                </BootstrapTooltip>
              ) : (
                <BootstrapTooltip title="Expand Left Bar">
                  <span>
                    <IconButton
                      style={{
                        padding: "0px",
                        width: "24px",
                        height: "24px",
                        backgroundColor: "#fff",
                        transform: "rotate(180deg)",
                      }}
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleSideNavState}
                      edge="start"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-align-justified"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1"
                        stroke="#131722"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M4 6l16 0" />
                        <path d="M4 12l16 0" />
                        <path d="M4 18l12 0" />
                      </svg>
                    </IconButton>
                  </span>
                </BootstrapTooltip>
              )}
            </div> */}
          </div>

          <ChatHistory
            data={copilotHistoryOutputList}
            loading={chatHistoryLoading}
          />

          {/* <Divider /> */}

          {administrator ? (
            <List className="DR-new-side-nav Dr-setting-btm">
              <ListItem
                className={
                  pathname === "/settings" ||
                  url === "exception-list" ||
                  url === "sl" ||
                  url === "hs" ||
                  url === "advance" ||
                  url === "sub" ||
                  url === "users" ||
                  url === "activity-log" ||
                  url === "sources" ||
                  url === "logs" ||
                  url === "goals" ||
                  url === "biosim" ||
                  url === "nevo-charts" ||
                  url === "ack" ||
                  url === "partial" ||
                  url === "DragDropLayout" ||
                  url === "graph-details" ||
                  url === "prior-auth" ||
                  url === "mapbox-testing" ||
                  url === "labels" ||
                  url === "queues-bucket" ||
                  url === "login-attempts"
                    ? " DR-link-active v3-icon-gre"
                    : "v3-icon"
                }
              >
                <LightTooltip
                  className={openDrawer ? "DR-side-tool" : ""}
                  title="Settings"
                >
                  <NavLink to={browserRoute.SETTING_DASHBOARD}>
                    <ListItemIcon>
                      <V2SettingIcon />
                    </ListItemIcon>
                    <ListItemText>Settings</ListItemText>
                  </NavLink>
                </LightTooltip>
              </ListItem>
            </List>
          ) : null}
        </div>
      </Drawer>
    </div>
  );
};

export default SideBarBot;
