import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import report from "./../../../../actions/report";
import {
  resetListingState,
  setListingState,
} from "../../filters/claimManagerFilters";
import {
  commonPagination,
  getCurrentClientId,
  getMultiSelectFilterValueForApi,
  getTreeNodeObj,
} from "../../../../components/common/Utils/utils";
import { getClaimAllFiltersData_Call } from "../../DenialDetails/claimAllFiltersSlice";
import store from "../../../store";

export const GroupByCptReportSlice = createSlice({
  name: "GroupByCptReportSlice",
  initialState: {
    denialsGBCPT: [],
    denialsGBPayer: [],
    denialsGBReasonCode: [],
    serviceLineFilterArr: [],
    cptCodesFilterArr: [],
    payersFilterArr: [],
    totalRecords: 0,
  },
  reducers: {
    denialsGBCPT_Action: (state, action) => {
      state.denialsGBCPT = action.payload;
      if (action?.payload?.length > 0) {
        state.totalRecords = action.payload[0]?.rowsCount;
      } else {
        state.totalRecords = 0;
      }
    },
    // filters for above state screen
    setDenialsByServiceLineFilters: (state, action) => {
      const data = action?.payload;

      //Service Line Filter Arr
      if (data?.CPTCodeJson) {
        state.serviceLineFilterArr = JSON.parse(data?.CPTCodeJson);
      } else {
        state.serviceLineFilterArr = [];
      }
      //CPT Codes
      if (data?.CptCodes?.length) {
        let newArr = [];
        data?.CptCodes?.forEach((item) => {
          let userFriendlyName = item?.description?.length
            ? `${item?.cptCode} - ${item?.description}`
            : `${item?.cptCode}`;
          newArr.push({
            value: item?.cptCode,
            count: item?.count,
            label: userFriendlyName,
          });
        });

        let descending = newArr.sort(
          (a, b) => Number(b.count) - Number(a.count)
        );

        state.cptCodesFilterArr = descending;
      } else {
        state.cptCodesFilterArr = [];
      }
      // for  payers filter Arr
      if (data?.payers?.length) {
        let newArr = [];
        data?.payers?.forEach((item) => {
          newArr.push({
            label: item?.payer,
            value: item?.id,
            // id: item.id,
          });
        });

        state.payersFilterArr = newArr;
      } else {
        state.payersFilterArr = [];
      }
    },

    denialsGBPayer_Action: (state, action) => {
      state.denialsGBPayer = action.payload;
    },

    denialsGBReasonCode_Action: (state, action) => {
      state.denialsGBReasonCode = action.payload;
    },
  },
});

export const {
  denialsGBCPT_Action,
  denialsGBPayer_Action,
  denialsGBReasonCode_Action,
  setDenialsByServiceLineFilters,
} = GroupByCptReportSlice.actions;

// denial group by CPT
export const denialsGBCPTCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "serviceLineDenialsReportFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }
    let sliceState =
      getState().npReducers.filters.serviceLineDenialsReportFilter;
    let sliceStateUser = getState().pReducers.user;

    let sliceStateForDate =
      getState().npReducers.filters.serviceLineDenialsReportFilter;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      ///// API CALL //////

      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate?.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate?.to,
        }),
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceState?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate?.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate?.to,
        }),
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceState?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
      };

      if (sliceState?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObj(sliceState.treeNodeServiceLine);
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
      }

      const cptParams = {
        CPTCode: "",
        start: 0,
        limit: 100,
        ...(sliceState?.cptCodes?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const payerParams = {
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const reasonCodeParams = {
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const providerParams = {
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        denialsGBCPT_Action,
        "serviceLineDenialsReportFilter",
        "GET_SERVICE_LINE_DENIALS_GB_CPT",
        false,
        "GET_SERVICE_LINE_DENIALS_FILTERS",
        setDenialsByServiceLineFilters
      );

      dispatch(
        getClaimAllFiltersData_Call(
          cptParams,
          payerParams,
          reasonCodeParams,
          providerParams,
          {},
          {},
          {
            isServiceLineAPI: true,
            isPayersAPI: true,
            isFillingIndicatorAPI: false,
            isIPOPRemartCodeAPI: false,
            isCptCodeAPI: true,

            isClaimStatusAPI: false,
            isReasonCodeAPI: false,
            isProviderAPI: false,
            isBillingProviderAPI: false,
            isRenderingProviderAPI: false,
            isGroupCodeAPI: false,
          }
        )
      );

      ////// API CALL END //////////////

      return 0;
    } catch (e) {
      // console.log("e--->>", e);
    }
  };
// denial group by CPT dropdown
export const denialsGBCPTDropdownCall = (obj) => async (dispatch, getState) => {
  let sliceState =
    getState().npReducers.filters.serviceLineDenialsReportFilter2Level;

  let sliceStateForDate =
    getState().npReducers.filters.reportSreensCommonFilter;

  // let start = 0;
  // let limit = 500;

  let start = obj.start;
  let limit = obj.limit;

  if (start >= 2) {
    let rec_val = (start - 1) * limit;
    start = rec_val;
  }
  if (start == 1) {
    start = 1;
  }

  try {
    ///// API CALL //////

    const res = await report.getDenialsGBCPTDropdown({
      start,
      limit,
      ...(sliceStateForDate?.from !== "" && {
        from: sliceStateForDate?.from,
      }),
      ...(sliceStateForDate?.to !== "" && {
        to: sliceStateForDate?.to,
      }),
      ...(sliceState?.payers !== "" && {
        payers: sliceState.payers,
      }),
      ...(obj?.cptCodes !== "" && {
        cptCodes: obj.cptCodes,
      }),
      ...(obj?.reasonCode !== "" && {
        reasonCode: obj.reasonCode,
      }),
      ...(sliceState.order !== "" && {
        Order: sliceState.order,
      }),
      ...(sliceState.order !== "" && {
        OrderBy: sliceState.orderBy,
      }),
    });

    ////// API CALL END //////////////

    return res?.data?.data;
  } catch (e) {
    // console.log("e--->>", e);

    return false;
  } finally {
    dispatch(
      setListingState({
        listing: "serviceLineDenialsReportFilter2Level",
        value: {
          loadingMore: "",
        },
      })
    );
  }
};

// denial groupo by payer
export const denialsGBPayerCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "serviceLineDenialsReportFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState =
      getState().npReducers.filters.serviceLineDenialsReportFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    try {
      dispatch(
        setListingState({
          listing: "serviceLineDenialsReportFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await report.getDenialsGBPayer({
        start,
        limit,
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
      });

      ////// API CALL END //////////////

      if (!res?.data?.success) {
        toast.error(`Something went wrong`, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(denialsGBPayer_Action([]));
        dispatch(
          setListingState({
            listing: "serviceLineDenialsReportFilter",
            value: {
              // hasMore: res?.data?.data.output.length === sliceState.pageSize,
              loadingMore: "",
            },
          })
        );
      } else {
        dispatch(
          setListingState({
            listing: "serviceLineDenialsReportFilter",
            value: {
              hasMore: res?.data?.data?.output?.length === sliceState.pageSize,
              loadingMore: "",
            },
          })
        );
        //   console.log("data--->>", res.data);
        dispatch(denialsGBPayer_Action(res?.data?.data));
      }
      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "serviceLineDenialsReportFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      return false;
    }
  };

// denial group by payer dropdown
export const denialsGBPayerDropdownCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      // dispatch(
      //   resetListingState({
      //     listing: "serviceLineDenialsReportFilter",
      //     ignore: [],
      //   })
      // );
    }

    let sliceState =
      getState().npReducers.filters.serviceLineDenialsReportFilter;
    let start = sliceState.dropdownPageNumber;
    let limit = sliceState.dropdownPageSize;

    try {
      // dispatch(
      //   setListingState({
      //     listing: "serviceLineDenialsReportFilter",
      //     value: {
      //       loadingMore: "data-loading",
      //     },
      //   })
      // );

      ///// API CALL //////

      const res = await report.getDenialsGBPayerDropdown({
        start,
        limit,
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState?.payers !== "" && {
          payers: sliceState.payers,
        }),
        ...(sliceState?.cptCodes !== "" && {
          cptCodes: sliceState.cptCodes,
        }),
        ...(sliceState?.reasonCode !== "" && {
          reasonCode: sliceState.reasonCode,
        }),
      });

      ////// API CALL END //////////////

      // dispatch(
      //   setListingState({
      //     listing: "serviceLineDenialsReportFilter",
      //     value: {
      //       hasMore: res?.data?.data?.output?.length === sliceState.pageSize,
      //       loadingMore: "",
      //     },
      //   })
      // );
      // dispatch(denialsGBCPT_Action(res?.data?.data));
      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      // dispatch(
      //   setListingState({
      //     listing: "serviceLineDenialsReportFilter",
      //     value: {
      //       initialLoading: false,
      //       loadingMore: "",
      //       hasMore: false,
      //     },
      //   })
      // );
      return false;
    }
  };

// denial group by reasonCode
export const denialsGBReasonCodeCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "serviceLineDenialsReportFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState =
      getState().npReducers.filters.serviceLineDenialsReportFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    try {
      dispatch(
        setListingState({
          listing: "serviceLineDenialsReportFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await report.getDenialsGBReasonCode({
        start,
        limit,
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
      });

      ////// API CALL END //////////////
      if (!res?.data?.success) {
        toast.error(`Something went wrong`, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(denialsGBReasonCode_Action([]));
        dispatch(
          setListingState({
            listing: "serviceLineDenialsReportFilter",
            value: {
              // hasMore: res?.data?.data.output.length === sliceState.pageSize,
              loadingMore: "",
            },
          })
        );
      } else {
        dispatch(
          setListingState({
            listing: "serviceLineDenialsReportFilter",
            value: {
              hasMore: res?.data?.data?.output?.length === sliceState.pageSize,
              loadingMore: "",
            },
          })
        );
        //   console.log("data--->>", res.data);
        dispatch(denialsGBReasonCode_Action(res?.data?.data));
      }

      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "serviceLineDenialsReportFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      return false;
    }
  };

// denial group by reasonCode dropdown
export const denialsGBReasonCodeDropdownCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      // dispatch(
      //   resetListingState({
      //     listing: "serviceLineDenialsReportFilter",
      //     ignore: [],
      //   })
      // );
    }

    let sliceState =
      getState().npReducers.filters.serviceLineDenialsReportFilter;
    let start = sliceState.dropdownPageNumber;
    let limit = sliceState.dropdownPageSize;

    try {
      // dispatch(
      //   setListingState({
      //     listing: "serviceLineDenialsReportFilter",
      //     value: {
      //       loadingMore: "data-loading",
      //     },
      //   })
      // );

      ///// API CALL //////

      const res = await report.getDenialsGBReasonCodeDropdown({
        start,
        limit,
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState?.payers !== "" && {
          payers: sliceState.payers,
        }),
        ...(sliceState?.cptCodes !== "" && {
          cptCodes: sliceState.cptCodes,
        }),
        ...(sliceState?.reasonCode !== "" && {
          reasonCode: sliceState.reasonCode,
        }),
      });

      ////// API CALL END //////////////

      // dispatch(
      //   setListingState({
      //     listing: "serviceLineDenialsReportFilter",
      //     value: {
      //       hasMore: res?.data?.data?.output?.length === sliceState.pageSize,
      //       loadingMore: "",
      //     },
      //   })
      // );
      // dispatch(denialsGBCPT_Action(res?.data?.data));
      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      // dispatch(
      //   setListingState({
      //     listing: "serviceLineDenialsReportFilter",
      //     value: {
      //       initialLoading: false,
      //       loadingMore: "",
      //       hasMore: false,
      //     },
      //   })
      // );
      return false;
    }
  };

export default GroupByCptReportSlice.reducer;
