import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { common } from "../../../../actions";

import { getServiceLineIndicator } from "../../../../actions/predictiveAnalytics";
import {
  getMultiSelectFilterValueForApi,
  getMultiSelectFilterIdForApi_ForId,
  commonPagination,
  getAscArray,
  getTreeNodeObj,
  getMultiSelectFilterValueFor837Payers,
  getCurrentClientId,
} from "../../../../components/common/Utils/utils";
// import { serverRoutes } from "./../../../../Constants/serverRoutes";
// import genericAxiosCall from "./../../../../AxiosConfig/genericAxiosCall";
import {
  setListingState,
  resetListingState,
  resetListingStateForResetButton,
} from "../../filters/claimManagerFilters";
import { getClaimAll837FiltersData_Call } from "../../DenialDetails/claim837AllFiltersSlice";
import store from "../../../store";

export const claimManagerSlice = createSlice({
  name: "claimManager",
  initialState: {
    claimData: { count: null, totalChargeAmount: null, output: [] },
    claimManager_Data: { count: null, totalChargeAmount: null, output: [] },
    claimDetail: [],

    // below code for new logic for filters on 837 screen

    payersForFilterArr: [],
    serviceLineFilterArr: [],
    providersForFilterArr: [],
    cptCodesForFilterArr: [],
    diagCodesForFilterArr: [],
    claimFrequencyForFilterArr: [],
    cptCodesForManager: [],
    diagCodesForManager: [],
    claimFillingIndiCodeForFilterArr: [],
    jCodeForFilterArr: [],
    claimStatus: [
      { value: "Accepted", label: "Accepted" },
      { value: "Rejected", label: "Rejected" },
      { value: "Open", label: "Open" },
    ],
    claimFrequency: [
      { value: 1, label: "Original" },
      { value: 7, label: "Replacement" },
      { value: 8, label: "Void/Cancel" },
    ],
  },
  reducers: {
    claim_DataAction: (state, action) => {
      let payload = action.payload;
      if (payload?.length > -1) {
        state.claimData.count = payload[0]?.count;
        state.claimData.totalChargeAmount = payload[0]?.totalChargeAmount;
        state.claimData.output = payload;
      } else {
        state.claimData.count = null;
        state.claimData.totalChargeAmount = null;
        state.claimData.output = [];
      }
    },

    removeClaimDetailById: (state, action) => {
      state.claimData.output = state?.claimData.output?.filter(
        (item) => item.claimRequestId !== action.payload
      );
    },

    // below code for new logic for filters on 837 screen

    claimManager_DataAction: (state, action) => {
      let data = action.payload;

      if (data?.claims?.length) {
        state.claimData.output = data?.claims;
        state.claimData.count = data?.claims[0]?.count;
        state.claimData.totalChargeAmount = data?.claims[0]?.totalChargeAmount;
      } else {
        state.claimData.output = [];
        state.claimData.count = null;
        state.claimData.totalChargeAmount = null;
      }
    },

    claimManagerFilters_DataAction: (state, action) => {
      let data = action.payload;
      // for  Payers filter Arr
      if (data?.payers?.length) {
        let newArr = [];
        data?.payers?.forEach((item) => {
          newArr.push({
            label: item?.payer,
            value: item?.id,
          });
        });

        state.payersForFilterArr = newArr;
      } else {
        state.payersForFilterArr = [];
      }
      if (data?.CPTCodeJSON) {
        state.serviceLineFilterArr = JSON.parse(data?.CPTCodeJSON);
      } else {
        state.serviceLineFilterArr = [];
      }
      // for  billingProviders filter Arr
      if (data?.billingProviders?.length) {
        let newArr = [];
        data?.billingProviders?.forEach((item) => {
          newArr.push({
            label: item?.name,
            value: item?.billingProviderId,
          });
        });

        state.providersForFilterArr = newArr;
      } else {
        state.providersForFilterArr = [];
      }

      // for  FilingIndicatorCode filter Arr
      if (data?.FilingIndicatorCode?.length) {
        let newArr = [];
        data?.FilingIndicatorCode?.forEach((item) => {
          let userFriendlyName = `${item?.description}`;
          newArr.push({
            value: item?.claimFilingIndicatorCode,
            label: userFriendlyName,
          });
        });

        state.claimFillingIndiCodeForFilterArr = newArr;
      } else {
        state.claimFillingIndiCodeForFilterArr = [];
      }

      // for  JCode filter Arr
      if (data?.JCode?.length) {
        let newArr = [];
        data?.JCode?.forEach((item) => {
          let userFriendlyName = item.drugName.length
            ? `${item.code} - ${item.drugName}`
            : `${item.code}`;
          newArr.push({
            value: item?.code,
            label: userFriendlyName,
          });
        });

        state.jCodeForFilterArr = newArr;
      } else {
        state.jCodeForFilterArr = [];
      }

      // for  cptCodes filter Arr
      if (data?.cptCodes?.length) {
        let newArr = [];
        data?.cptCodes?.forEach((item) => {
          if (item?.cptCode !== "") {
            let userFriendlyName = item?.description
              ? `${item.cptCode} - ${item.description}`
              : `${item.cptCode}`;
            newArr.push({
              label: userFriendlyName,
              value: item?.cptCode,
            });
          }
        });

        state.cptCodesForFilterArr = newArr;
      } else {
        state.cptCodesForFilterArr = [];
      }

      // for  diagnoseCodes filter Arr
      if (data?.diagnoseCodes?.length) {
        let newArr = [];
        data?.diagnoseCodes?.forEach((item) => {
          newArr.push({
            label: item?.diagnosisCode,
            value: item?.diagnosisCode,
          });
        });

        state.diagCodesForFilterArr = newArr;
      } else {
        state.diagCodesForFilterArr = [];
      }

      // for  ClaimFrequencyType filter Arr
      if (data?.ClaimFrequencyType && data?.ClaimFrequencyType?.length) {
        let sortFrequecyCode = getAscArray(
          data?.ClaimFrequencyType,
          "frequencyCode"
        );
        let newArr = [];
        sortFrequecyCode?.forEach((item) => {
          let userFriendlyName = item.description.length
            ? `${item.frequencyCode} - ${item.description}`
            : `${item.frequencyCode}`;
          newArr.push({
            value: item?.frequencyCode,
            label: userFriendlyName,
          });
        });

        state.claimFrequencyForFilterArr = newArr;
      } else {
        state.claimFrequencyForFilterArr = [];
      }
    },
  },
});

export const {
  claim_DataAction,
  removeClaimDetailById,
  claimManager_DataAction,
  claimManagerFilters_DataAction,
} = claimManagerSlice.actions;

// getting Claim Request For Filter test compo (837) table data 2nd api
export const claimManagerRequestForFilter2ndAPI_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      if (clear === true) {
        dispatch(
          resetListingState({
            listing: "claimManager",
            ignore: ["claimStatus,payers,cptCodes,CARC"],
          })
        );
      }
      if (clear === "resetBtn") {
        dispatch(
          resetListingStateForResetButton({
            listing: "claimManager",
            ignore: [
              "BillingProviderIds",
              "frequencyType",
              "frequencyType",
              "search",
            ],
          })
        );
      }
    }
    // console.log("claimManagerRequestForFilter2ndAPI_Call--->>");
    let sliceState = getState().npReducers.filters.claimManager;
    let sliceStateUser = getState().pReducers.user;
    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceStateForDate =
      getState().npReducers.filters.denialSreensCommonFilter;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      // console.log("sliceState--->>", sliceState);
      let paramsTemp = {
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterIdForApi_ForId(sliceState?.payers),
        }),
        ...(sliceState?.search && { Filter: sliceState?.search }),

        ...(sliceState?.frequencyType?.length > 0 && {
          FrequencyCode: getMultiSelectFilterValueForApi(
            sliceState?.frequencyType
          ),
        }),
        ...(sliceState?.claimStatus?.length > 0 && {
          ClaimStatus: getMultiSelectFilterValueForApi(sliceState?.claimStatus),
        }),
        ...(sliceState?.DiagnosisCode?.length > 0 && {
          DiagnosisCode: getMultiSelectFilterValueForApi(
            sliceState?.DiagnosisCode
          ),
        }),
        ...(sliceState?.cptCodes?.length > 0 && {
          CPTCode: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),

        ...(sliceState?.serviceLineTypeonCology &&
          sliceState?.serviceLine?.length > 0 && {
            type: getMultiSelectFilterIdForApi_ForId(sliceState?.serviceLine),
          }),

        ...(sliceState?.billingProviders?.length > 0 && {
          billingProvider: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          FillingIndicatorCode: getMultiSelectFilterIdForApi_ForId(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProvider: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceState?.attendingProviders?.length > 0 && {
          attendingProvider: getMultiSelectFilterValueForApi(
            sliceState?.attendingProviders
          ),
        }),

        ...(sliceState?.drgCodes?.length > 0 && {
          DRGCode: getMultiSelectFilterValueForApi(sliceState?.drgCodes),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(!sliceState?.search &&
          sliceStateForDate?.from !== "" && {
            from: sliceStateForDate?.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate?.to !== "" && {
            to: sliceStateForDate?.to,
          }),
        ...(sliceState?.IsIncorrectBundled &&
          sliceState?.IsIncorrectBundled !== false && {
            IsIncorrectBundled: sliceState?.IsIncorrectBundled,
          }),
        ...(sliceState?.IsExceedUnit &&
          sliceState?.IsExceedUnit !== false && {
            IsExceedUnit: sliceState?.IsExceedUnit,
          }),
        ...(sliceState?.isSuggestedCPT &&
          sliceState?.isSuggestedCPT !== false && {
            isSuggestedCPT: sliceState?.isSuggestedCPT,
          }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          ...(sliceStateUser?.loginUserData?.isRcm && {
            clientId:
              Array.isArray(sliceStateForClientId?.clientId) &&
              sliceStateForClientId?.clientId.length > 0
                ? sliceStateForClientId?.clientId[0].value
                : sliceStateForClientId?.clientId || getCurrentClientId(),
          }),
        }),
        ...(clear instanceof Date && { timestamp: clear.getTime() }), // Add a timestamp if clear is a Date object
      };
      let params = {
        start,
        limit,

        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterIdForApi_ForId(sliceState?.payers),
        }),
        ...(sliceState?.search && { Filter: sliceState?.search }),

        ...(sliceState?.frequencyType?.length > 0 && {
          FrequencyCode: getMultiSelectFilterValueForApi(
            sliceState?.frequencyType
          ),
        }),
        ...(sliceState?.claimStatus?.length > 0 && {
          ClaimStatus: getMultiSelectFilterValueForApi(sliceState?.claimStatus),
        }),
        ...(sliceState?.DiagnosisCode?.length > 0 && {
          DiagnosisCode: getMultiSelectFilterValueForApi(
            sliceState?.DiagnosisCode
          ),
        }),
        ...(sliceState?.cptCodes?.length > 0 && {
          CPTCode: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),

        ...(sliceState?.serviceLine?.length > 0 && {
          type: getMultiSelectFilterIdForApi_ForId(sliceState?.serviceLine),
        }),

        ...(sliceState?.billingProviders?.length > 0 && {
          billingProvider: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          FillingIndicatorCode: getMultiSelectFilterIdForApi_ForId(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProvider: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceState?.attendingProviders?.length > 0 && {
          attendingProvider: getMultiSelectFilterValueForApi(
            sliceState?.attendingProviders
          ),
        }),

        ...(sliceState?.drgCodes?.length > 0 && {
          DRGCode: getMultiSelectFilterValueForApi(sliceState?.drgCodes),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(!sliceState?.search &&
          sliceStateForDate?.from !== "" && {
            from: sliceStateForDate?.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate?.to !== "" && {
            to: sliceStateForDate?.to,
          }),
        ...(sliceState?.IsIncorrectBundled &&
          sliceState?.IsIncorrectBundled !== false && {
            IsIncorrectBundled: sliceState?.IsIncorrectBundled,
          }),
        ...(sliceState?.IsExceedUnit &&
          sliceState?.IsExceedUnit !== false && {
            IsExceedUnit: sliceState?.IsExceedUnit,
          }),
        ...(sliceState?.isSuggestedCPT &&
          sliceState?.isSuggestedCPT !== false && {
            isSuggestedCPT: sliceState?.isSuggestedCPT,
          }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          ...(sliceStateUser?.loginUserData?.isRcm && {
            clientId:
              Array.isArray(sliceStateForClientId?.clientId) &&
              sliceStateForClientId?.clientId.length > 0
                ? sliceStateForClientId?.clientId[0].value
                : sliceStateForClientId?.clientId || getCurrentClientId(),
          }),
        }),
      };

      if (sliceState?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObj(sliceState.treeNodeServiceLine);
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
      }

      const cptParams = {
        CPTCode: "",
        start: 0,
        limit: 100,
        ...(sliceState?.cptCodes?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate?.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate?.to,
        }),
      };

      const payersParams = {
        searchKeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.payers?.length > 0 && {
          selectedPayers: getMultiSelectFilterValueFor837Payers(
            sliceState?.payers
          ),
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate?.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate?.to,
        }),
      };

      const diagnosisParams = {
        searchKeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.DiagnosisCode?.length > 0 && {
          SelectedDiagnosisCode: getMultiSelectFilterValueForApi(
            sliceState?.DiagnosisCode
          ),
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate?.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate?.to,
        }),
      };

      const billingProviderParams = {
        searchKeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.billingProviders?.length > 0 && {
          selectedBillingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate?.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate?.to,
        }),
      };

      const renderingProviderParams = {
        searchKeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.renderingProviders?.length > 0 && {
          selectedRenderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate?.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate?.to,
        }),
      };

      const attendingProviderParams = {
        searchKeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.attendingProviders?.length > 0 && {
          selectedAttendingProviders: getMultiSelectFilterValueForApi(
            sliceState?.attendingProviders
          ),
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate?.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate?.to,
        }),
      };

      // start=0&limit=10&selectedDRGs=559&from=02/25/2020&to=03/12/2024&ClientId=13
      const drgCodeParams = {
        searchKeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.drgCodes?.length > 0 && {
          selectedDRGs: getMultiSelectFilterValueForApi(sliceState?.drgCodes),
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate?.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate?.to,
        }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        claimManager_DataAction,
        "claimManager",
        "GET_CLAIM_REQUEST_837_TABULAR_DATA_API",
        false,
        "",
        ""
      );

      dispatch(
        getClaimAll837FiltersData_Call(
          cptParams,
          payersParams,
          diagnosisParams,
          billingProviderParams,
          renderingProviderParams,
          attendingProviderParams,
          drgCodeParams,
          {
            isServiceLineAPI: true,
            isPayersAPI: true,
            isFillingIndicatorAPI: true,
            isBillingProviderAPI: true,
            isRenderingProviderAPI: true,
            isAttendingProviderAPI: true,
            isProviderAPI: false,
            isFrequencyAPI: true,
            isDiagCodeAPI: true,
            isCptAPI: true,
            isDrgCodeAPI: true,
          }
        )
      );
      return 0;
    } catch (e) {
      console.log("catch_err--->>", e);
    }
  };

// getting Claim Request (837) table data
export const claimRequestCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      if (clear === true) {
        dispatch(
          resetListingState({
            listing: "claimManager",
            ignore: ["claimStatus", "payers", "frequencyType", "search"],
          })
        );
      }
      if (clear === "resetBtn") {
        dispatch(
          resetListingStateForResetButton({
            listing: "claimManager",
            ignore: ["claimStatus", "payers", "frequencyType", "search"],
          })
        );
      }
    } else {
    }

    let sliceState = getState().npReducers.filters.claimManager;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      dispatch(
        setListingState({
          listing: "claimManager",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await common.getClaimRequest({
        start,
        limit,
        ...(sliceState?.search && { Filter: sliceState.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterIdForApi_ForId(sliceState?.payers),
        }),
        ...(sliceState?.BillingProvider?.length > 0 && {
          BillingProviderIds: getMultiSelectFilterIdForApi_ForId(
            sliceState?.BillingProvider
          ),
        }),
        ...(sliceState?.frequencyType?.length > 0 && {
          FrequencyCode: getMultiSelectFilterValueForApi(
            sliceState?.frequencyType
          ),
        }),
        ...(sliceState?.claimStatus?.length > 0 && {
          ClaimStatus: getMultiSelectFilterValueForApi(sliceState?.claimStatus),
        }),
        ...(sliceState?.DiagnosisCode?.length > 0 && {
          DiagnosisCode: getMultiSelectFilterValueForApi(
            sliceState?.DiagnosisCode
          ),
        }),
        ...(sliceState?.CPTCode?.length > 0 && {
          CPTCode: getMultiSelectFilterValueForApi(sliceState?.CPTCode),
        }),
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(!sliceState?.search &&
          sliceState?.from !== "" && {
            from: sliceState?.from,
          }),
        ...(!sliceState?.search &&
          sliceState?.to !== "" && {
            to: sliceState?.to,
          }),
      });
      ////// //////////////

      if (!res?.data?.success) {
        toast.error(`Something went wrong`, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(claim_DataAction([]));
        dispatch(
          setListingState({
            listing: "claimManager",
            value: {
              // hasMore: res?.data?.data.output.length === sliceState.pageSize,
              loadingMore: "",
            },
          })
        );
      } else {
        dispatch(claim_DataAction(res?.data?.data));
        // alert("success_true--->>");
        dispatch(
          setListingState({
            listing: "claimManager",
            value: {
              hasMore: res?.data?.data?.length === sliceState.pageSize,
              loadingMore: "",
            },
          })
        );
      }

      return res?.data;
    } catch (e) {
      // console.log("catch_err--->>", e);

      dispatch(
        setListingState({
          listing: "claimManager",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      dispatch(claim_DataAction([]));
      return false;
    }
  };

export const deleteClaimRequestCall = (claimId) => async (dispatch) => {
  try {
    const res = await common.deleteClaimRequest(claimId);

    if (res?.data?.success) {
      dispatch(removeClaimDetailById(claimId));
      return res;
    } else {
      return res;
    }
  } catch (e) {
    // console.log("catch_e--->>", e);
  }
};

export const claimRequestDetailMLCall = (data) => async (dispatch) => {
  const claim = await getServiceLineIndicator(data);

  return claim?.data;
};

export default claimManagerSlice.reducer;
