import { Grid } from "@mui/material";
import LoadingIcon from "../../../assets/svgs/LoadingIcon";

const Loading = ({ compHeight, loaderWidth, loaderHeight }) => {
  return (
    <Grid container>
      <Grid item md={12} className="text-center">
        <div
          style={{
            // height: "200px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingIcon loaderWidth={loaderWidth} loaderHeight={loaderHeight} />
        </div>
      </Grid>
    </Grid>
  );
};

export default Loading;
