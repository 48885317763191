import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./index.css";
import "./assets/css/main-style.css";

import * as serviceWorker from "./serviceWorker";
import store from "./Redux/store";
import { msalConfig } from "./AuthenticationAD/authConfig";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import ErrorBoundary from "./ErrorBoundries";

let persistor = persistStore(store);
export const msalInstance = new PublicClientApplication(msalConfig);
let splitItem = window.location.href?.includes("localhost");
// splitItem = splitItem?.[2]?.includes("localhost");
ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    {!splitItem ? (
      <ErrorBoundary>
        <MsalProvider instance={msalInstance}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
        </MsalProvider>
      </ErrorBoundary>
    ) : (
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </MsalProvider>
    )}
  </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
